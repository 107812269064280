<template>
  <section id="home_messages">
    <template v-if="activeCatalogs?.length">
      <Toast
        v-for="actif in activeCatalogs"
        :key="actif.nom"
        :title="$t('toast.catalogue-ferme', { nom: actif.nom, })"
        :content="$t('toast.catalogue-ferme-content')"
        show-icon
        hide-close-btn
      />
    </template>
    <!-- Message en provenance de 4D -->
    <template v-if="organismeActuel?.infos?.message_numerique">
      <Toast
        :content="organismeActuel.infos.message_numerique"
        show-icon
        hide-close-btn
      />
    </template>
    <!-- Message en provenance de l'admin Django -->
    <template v-if="messages.length">
      <Toast
        v-for="message in messages"
        :key="message.id"
        :title="message.titre"
        :content="message.contenu"
        :variant="message.type"
        show-icon
        @close="setMessageAsSeen(message.id)"
      />
    </template>
  </section>
</template>

<script>
import { Toast } from "@lde/core_lde_vue";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Message d'information si le catalogue est fermé, issu de 4D et de l'admin Django.
 */
export default {
  name: "HomeMessages",
  components: {
    Toast,
  },
  data() {
    return {
      messages: [],
    };
  },
  computed: {
    ...mapGetters(["user", "organismeActuel", "isHorsMarche"]),
    activeCatalogs() {
      const today = new Date();
      let debut;
      let fin;

      return this.organismeActuel?.catalogues_actifs.filter((actif) => {
        debut = new Date(actif.date_debut);
        fin = new Date(actif.date_fin);
        // Pour tester s'il est fermé :
        // debut = new Date("2021-09-15T00:00:00Z");
        // fin = new Date("2021-09-20T00:00:00Z");
        if (!(today.getTime() >= debut.getTime() && today.getTime() <= fin.getTime())) {
          return actif;
        }
        return null;
      });
    },
  },
  mounted() {
    this.fetchMessages();
  },
  methods: {
    /**
     * Récupère les messages et ignore les messages déjà vus.
     */
    fetchMessages() {
      Api().get("/message_info/")
        .then(({ data: { results } }) => {
          this.messages = results;
          this.setCurrentMessages();
        })
        .catch((error) => {
          this.$toast.error({ title: error });
        });
    },
    /**
     * Stock les messages déjà vus en mémoire.
     */
    setMessageAsSeen(msgId) {
      const closedAt = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      const closedMessages = JSON.parse(localStorage.getItem(`closedMessages.${this.user.id}`)) || [];
      closedMessages.push({ id: msgId, closedAt });
      localStorage.setItem(`closedMessages.${this.user.id}`, JSON.stringify(closedMessages));
      this.setCurrentMessages();
    },
    /**
     * Compare les messages et les messages fermés. Si la date de modification du message est plus récent,
     * l'utilisateur doit voir le message même si fermé précédement.
     */
    setCurrentMessages() {
      const closedMessages = JSON.parse(localStorage.getItem(`closedMessages.${this.user.id}`)) || [];
      this.messages = this.messages.filter((message) => !closedMessages.some((closed) => {
        // Si un message fermé a le même id et sa date de fermature est après msgUpdate, il faut l'exclure.
        const closedAtDate = this.$moment(closed.closedAt);
        const msgUpdate = this.$moment(message.date_modification);
        return closed.id === message.id && closedAtDate.isAfter(msgUpdate);
      }));
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#home_messages {
  .toast { margin-bottom: var.$space-small; }
}
</style>
